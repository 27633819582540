/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        AOS.init({
          once: true
        });

        $('.slick-carousel').slick({
          autoplay: true,
          autoplaySpeed: 6000,
          arrows: true,
          prevArrow:"<img class='slick-prev slick-arrow' src='https://www.roseyard.co.uk/wp-content/themes/roseyard/dist/images/icons/chevron-left-white.svg'>",
          nextArrow:"<img class='slick-next slick-arrow' src='https://www.roseyard.co.uk/wp-content/themes/roseyard/dist/images/icons/chevron-right-white.svg'>",
        });
        $('.slick-carousel-building').slick({
          arrows: true,
          prevArrow:"<img class='slick-prev slick-arrow' src='https://www.roseyard.co.uk/wp-content/themes/roseyard/dist/images/icons/chevron-left.svg'>",
          nextArrow:"<img class='slick-next slick-arrow' src='https://www.roseyard.co.uk/wp-content/themes/roseyard/dist/images/icons/chevron-right.svg'>",
        });
        $(".carousel-page-header").slick({
          autoplay: true,
          autoplaySpeed: 6000,
          arrows: true,
          prevArrow:"<img class='slick-prev slick-arrow' src='https://www.roseyard.co.uk/wp-content/themes/roseyard/dist/images/icons/chevron-left-white.svg'>",
          nextArrow:"<img class='slick-next slick-arrow' src='https://www.roseyard.co.uk/wp-content/themes/roseyard/dist/images/icons/chevron-right-white.svg'>",
        });
        $(".carousel-single-page-header").slick({
          autoplay: true,
          autoplaySpeed: 6000,
          arrows: true,
          prevArrow:"<img class='slick-prev slick-arrow' src='https://www.roseyard.co.uk/wp-content/themes/roseyard/dist/images/icons/chevron-left-white.svg'>",
          nextArrow:"<img class='slick-next slick-arrow' src='https://www.roseyard.co.uk/wp-content/themes/roseyard/dist/images/icons/chevron-right-white.svg'>",
        });
        $(".sub-page-carousel").slick({
          arrows: true,
          prevArrow:"<img class='slick-prev slick-arrow' src='https://www.roseyard.co.uk/wp-content/themes/roseyard/dist/images/icons/chevron-left-white.svg'>",
          nextArrow:"<img class='slick-next slick-arrow' src='https://www.roseyard.co.uk/wp-content/themes/roseyard/dist/images/icons/chevron-right-white.svg'>",
        });

        var siteBody = $("body, html");
        var menuBars = $(".menu-fa");
        var mobileNavigation = $(".header-mobile-menu");
        var closeButton = $(".close-button");

        menuBars.click(function() {
          mobileNavigation.fadeIn();
          $(window).scrollTop(0);
          siteBody.css('overflow', 'hidden');
          closeButton.fadeIn();
          menuBars.fadeOut();
        });

        closeButton.click(function() {
          mobileNavigation.fadeOut();
          siteBody.css('overflow', 'initial');
          menuBars.fadeIn();
          closeButton.fadeOut();
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        window.addEventListener('load', AOS.refresh);
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
        $(".about-us-gallery").slick({
          arrows: true,
          prevArrow:"<img class='slick-prev slick-arrow' src='https://www.roseyard.co.uk/wp-content/themes/roseyard/dist/images/icons/chevron-left-white.svg'>",
          nextArrow:"<img class='slick-next slick-arrow' src='https://www.roseyard.co.uk/wp-content/themes/roseyard/dist/images/icons/chevron-right-white.svg'>",
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
